import { INBOX_ROUTE_KEY, HOMEOWNER_INBOX_ROUTE_KEY } from '../constants/routes';
import { INote } from '../screens/Installer/components/inbox/types';
import { deriveUsageStateFromUrl } from './util';

/**
 * A function that accepts a full name and reduces it to initials
 *
 * @param {String} a person's full name
 * @returns {String} the person's initials
 */
export const getInitialsFromName = (names: string) => {
  const initials = names
    .split(' ')
    .map((name: string) => {
      return name.charAt(0).toUpperCase();
    })
    .join('');
  return initials.charAt(0) + initials.charAt(initials.length - 1);
};

/**
 * Assess whether the user's current URL location matches Inbox feature.
 *
 * @returns Boolean
 */
export const userIsUsingInbox = (domain: string = 'installer') => {
  const derivedUsage = deriveUsageStateFromUrl();
  if (domain === 'homeowner') {
    return derivedUsage.features.length && derivedUsage.features[0] === HOMEOWNER_INBOX_ROUTE_KEY;
  }
  return derivedUsage.features.length && derivedUsage.features.includes(INBOX_ROUTE_KEY);
};

/**
 * Provides a mechanism to throttle calls for expensive operations to ensure smooth UX
 *
 * @param {Function} fn
 * @param {Number} interval in milliseconds
 * @returns undefined
 */
export function withThrottle(fn: (args: any) => void, interval: number) {
  let waiting = false;
  return function () {
    if (waiting) {
      return;
    }

    waiting = true;
    setTimeout(() => {
      fn.apply(this, arguments);
      waiting = false;
    }, interval);
  };
}

/**
 * A canonical mechanism to get a touchName from the API to compare against another domain object from the API
 *
 * @param {string} touchName
 * @returns {?String}
 */
export const processTouchNameForCollation = (touchName: string): string => {
  return touchName && touchName.toLowerCase().replace(/ /g, '');
};

/**
 * Accepts a number and returns a string representation designed to reflect a standard "count" of things
 *
 * @param {Number} the count of something
 * @returns {String}
 */
export const standardCountFormat = (number: number): string => {
  const decimals = 0;
  if (number > 99 && number <= 999) {
    return String('99+');
  }
  if (number > 999 && number <= 99999) {
    return `${String(Math.round(number / 1000).toFixed(decimals))}K+`;
  }
  return String(number);
};

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

/**
 * Generate a simple string value to be used for a DOM Node id attribute
 *
 * @param {?Number} length of the string to be generated
 * @returns {String}
 */
export const createNonUniqueId = (length?: number) => {
  let result = '';
  const charsLength = chars.length;
  const counter = length || 30;
  for (let i = 0; i < counter; i += 1) {
    result += chars.charAt(Math.floor(Math.random() * charsLength));
  }
  return result;
};

/**
 * Simple test to determine whether it resembles a valid URL or not
 *
 * @param {String} test
 * @returns {Boolean}
 */
export const isValidUrl = (test: string) => {
  let url;
  try {
    url = new URL(test);
  } catch (e) {
    return false;
  }
  const { protocol } = url;
  return protocol === 'http:' || protocol === 'https:';
};


export const moveItemInArrayFromIndexToIndex = (array:INote[], fromIndex:number, toIndex:number) => {
  if (fromIndex === toIndex) return array;

  const newArray:INote[] = [...array];
  const target = newArray[fromIndex];
  const inc = toIndex < fromIndex ? -1 : 1;

  for (let i = fromIndex; i !== toIndex; i += inc) {
    newArray[i] = newArray[i + inc];
  }

  newArray[toIndex] = target;
  return newArray;
};

export const isValidChurnZeroUser = (email: string, name: string) => {
  if (!name.includes('test') && !email.includes('bodhi') && !email.includes('17terawatts')) {
    return true;
  } else {
    return false;
  }
}
